import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, desktop } = BREAKPOINTS;

const HomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    ". header ."
    ". hero ."
    ". banners ."
    ". main ."
    "footer footer footer";
  padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));

  @media (max-width: ${tablet}px) {
    padding-top: 0;
  }

  .Home {
    &__header {
      grid-column: 1 / -1;
    }

    &__hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-column: 1 / -1;
      width: 100%;
      position: relative;

      .Image__img {
        object-fit: cover;
        width: 100%;

        @media (max-width: ${tablet}px) {
          margin-top: ${props =>
            props.showStoreNotification
              ? "calc( var(--sizes-navbar-height-tablet-with-search-with-close-component) - env(safe-area-inset-top))"
              : "calc( var(--sizes-navbar-height-tablet-with-search-without-close-component) - env(safe-area-inset-top))"};
        }
      }

      &__banner {
        border-radius: 0;
        height: auto;
        width: 100%;
      }

      &__button {
        padding: 1.6rem;
        background-color: var(--palette-white);
        color: var(--palette-primary);
        width: 18.2rem;
        border-radius: 4rem;
        position: absolute;
        bottom: 6.4rem;
        border: 0;

        @media (max-width: ${tablet}px) {
          width: 24rem;
        }

        @media (max-width: ${tablet}px) {
          bottom: 7.2rem;
          padding: 1.2rem 1.6rem;
        }

        .Button__wrapper {
          font-weight: 600;
          font-size: 1.6rem;
        }

        svg {
          path {
            fill: var(--palette-primary);
          }
        }

        &:hover:not([disabled]) {
          background-color: var(--palette-white);
          color: var(--palette-primary);
          border: unset;
          box-shadow: unset;
        }
      }
    }

    &__banners {
      grid-column: 2 / -1;

      @media (max-width: ${desktop}px) {
        padding-left: 1.6rem;
      }
    }

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-column: content-start / content-end;
    }

    &__slider__title {
      margin-top: 4rem;
      margin-bottom: 3.2rem;
      font-size: 3.2rem;
      font-weight: 800;

      color: var(--palette-black-s0-l10);

      span {
        color: var(--palette-primary);
      }
    }

    &__carousel {
      position: relative;

      & .Image__img {
        object-fit: cover;
      }
    }

    &__cards {
      display: flex;
      margin-top: 4rem;

      @media (max-width: ${desktop - 1}px) {
        flex-direction: column;
      }
    }

    &__card {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 2.4rem 1.6rem;
      padding-bottom: 0;
      border-radius: 2rem;
      background-color: var(--palette-primary);
      overflow: hidden;

      @media (max-width: ${tablet}px) {
        overflow: unset;
        flex-direction: column;
      }

      :last-child {
        background-color: var(--palette-brown-s82-l26);
        margin-left: 2.4rem;

        @media (max-width: ${desktop - 1}px) {
          margin-left: 0;
          margin-top: 1.6rem;
        }
      }

      &__content {
      }

      &__label {
        display: inline-block;
        margin-bottom: 2.8rem;
        font-size: 1.8rem;
        font-weight: 800;
        color: var(--palette-white);

        @media (max-width: ${tablet}px) {
          font-size: 1.6rem;
          margin-bottom: 2rem;
        }

        span {
          color: var(--palette-primary);
        }
      }

      &__title {
        max-width: 33.6rem;
        margin-bottom: 1.6rem;
        font-size: 4rem;
        font-weight: 800;
        color: var(--palette-white);
        line-height: 4rem;

        @media (max-width: ${tablet}px) {
          font-size: 2.4rem;
          line-height: 2.4rem;
          margin-bottom: 0.8rem;
        }
      }

      &__description {
        max-width: 30.4rem;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        color: var(--palette-white);

        @media (max-width: ${tablet}px) {
          font-size: 1.6rem;
          margin-bottom: 2.4rem;
        }
      }

      &__coupon-input {
        .TextInput {
          label.TextInput__label {
            font-size: 1.2rem;
            color: var(--palette-white);
            font-weight: 800;
          }

          input.TextInput__input {
            font-size: 1.6rem;
            color: var(--palette-white);
            border: none;
            background-color: var(--palette-green-s100-l14);

            &::placeholder {
              color: var(--palette-green-s24-l65);
            }
          }
        }

        .Button {
          color: var(--palette-white);

          &:hover {
            color: var(--palette-secondary-s35-l95);
          }
        }
      }

      &__download-link {
        text-decoration: none;
      }

      &__download-app-button {
        padding: 1.2rem 2rem;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        font-weight: 800;
        border-radius: 2.4rem;
        background-color: var(--palette-primary);
        color: var(--palette-white);

        &.Button {
          &:hover {
            box-shadow: none;
          }
        }
      }

      &__image-container {
        display: flex;
        align-items: flex-end;
        width: 263px;
        height: 320px;
        position: relative;

        @media (max-width: ${tablet}px) {
          justify-content: center;
        }
      }

      &__image {
        width: 100%;
      }
    }

    &__place-your-order,
    &__find-us {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 4.8rem;
      padding: 4rem 7.2rem;
      border-radius: 2rem;
      background-color: var(--palette-secondary-s35-l95);

      @media (max-width: ${tablet}px) {
        padding: 2.4rem 1.6rem;
        padding-bottom: 4rem;
        flex-direction: column;
      }

      &__texts {
        margin-right: 9.6rem;
        width: 100%;

        @media (max-width: ${tablet}px) {
          margin: 0;
        }
      }

      &__title {
        margin-bottom: 0.8rem;
        font-size: 3.2rem;
        font-weight: 900;
        color: var(--palette-black-s0-l20);

        @media (max-width: ${tablet}px) {
          font-size: 3rem;
        }
      }

      &__description {
        font-size: 1.6rem;
        font-weight: 400;
        color: var(--palette-black-s0-l20);

        @media (max-width: ${tablet}px) {
          margin-bottom: 2.4rem;
        }
      }

      &__buttons {
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;
        width: 100%;

        @media (max-width: ${tablet}px) {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
      }

      &__button {
        width: 100%;
        max-width: 20.8rem;
        height: 5.6rem;
        border-radius: 12rem;
        background-color: var(--palette-white);
        transition: transform 0.5s ease 0s;

        &:hover {
          transform: scale(1.05);
        }

        @media (max-width: ${tablet}px) {
          max-width: 31.2rem;
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;

          path {
            fill: var(--palette-primary);
          }
        }

        :first-child {
          margin-right: 2.4rem;

          @media (max-width: ${tablet}px) {
            margin: 0;
            margin-bottom: 1.6rem;
          }
        }

        &.Button--active {
          border-color: var(--palette-primary);

          &:hover {
            border-color: var(--palette-green-s100-l14);
            background-color: var(--palette-green-s100-l14);
          }
        }

        &-content {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          font-weight: 600;
          color: var(--palette-primary);
        }

        &-icon {
          margin-right: 0.8rem;
        }
      }
    }

    &__place-your-order {
      &__button {
        box-shadow: none;
        border: 0;

        &:hover {
          box-shadow: none;
          border: 0;
        }
      }

      .Button--active {
        background-color: var(--palette-primary);

        span {
          color: var(--palette-white);
        }

        path {
          fill: var(--palette-white);
        }
      }
    }

    &__find-us {
      margin-top: 4rem;
      padding: 1.6rem 7.2rem;
      background-color: var(--palette-brown-s5-l25);

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
      }

      &__texts {
        margin-right: 1.6rem;
        margin-left: 2.4rem;

        @media (max-width: ${tablet}px) {
          margin: 0;
          margin-top: 2.4rem;
        }
      }

      &__title {
        color: var(--palette-white);

        @media (max-width: ${tablet}px) {
          text-align: center;
        }
      }

      &__description {
        color: var(--palette-white);

        @media (max-width: ${tablet}px) {
          margin-bottom: 1.6rem;
          text-align: center;
        }
      }

      &__button {
        max-width: 25.6rem;
        margin-left: 0 auto;
        border: none;

        &.Button {
          :hover {
            background-color: var(--palette-white);
            box-shadow: none;
            border: 0;
          }
        }

        @media (max-width: ${tablet}px) {
          max-width: 25.6rem;
          background-color: var(--palette-white);
        }

        svg {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 1.2rem;

          @media (max-width: ${tablet}px) {
            path {
              fill: var(--palette-primary);
            }
          }
        }

        &-content {
          @media (max-width: ${tablet}px) {
            color: var(--palette-primary);
          }
        }
      }
    }

    &__footer {
      grid-area: footer;
      padding-top: 4rem;
    }
  }

  .RedeemCoupon__input {
    z-index: 0;
  }
`;

export default HomeStyled;
