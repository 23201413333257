import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import NextImage from "next/image";
import { ButtonGroup, Image } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { Banner } from "artisn/types";
import { Capacitor } from "@capacitor/core";
import Bugsnag from "@bugsnag/js";
import useWindowSize from "hooks/useWindowSize";
import useStores from "contexts/stores/stores.context.hooks";

import Styles from "./Home.styles";
import CONSTANTS, { DELIVERY, PICK_UP } from "config/constants";
import { HomeProps as Props } from "./Home.types";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import Button from "components/global/Button/Button";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { Workflow } from "types/workflow.types";
import RedeemCoupon from "components/Cart/RedeemCoupon/RedeemCoupon";
import BannersSlider from "components/global/BannersSlider/BannersSlider";
import { useFetchBanners } from "services/banners/banners.service.hooks";
import { goToBannerUrl } from "components/global/BannersSlider/BannersSlider.helpers";
import { useGetOrderRatingStatus } from "services/rating/rating.service.hooks";
import { useFetchLastCompletedOrder } from "services/orders/orders.service.hooks";
import RateModal from "components/profileOrder/RateModal/RateModal";
import { getOS } from "utils/global.utils";
import useProducts from "contexts/products/products.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";

import DeliverySVG from "../../../../public/assets/images/delivery.svg";
import PickUpSVG from "../../../../public/assets/images/pickup.svg";
import LocalSVG from "../../../../public/assets/images/pickup-home.svg";
import FindUsMapSVG from "../../../../public/assets/images/home/find-us-map.svg";

const { ARTISN, APP, FEATURE_FLAGS } = CONSTANTS;
const { WITH_PRODUCT_MODAL } = FEATURE_FLAGS;
const { CATALOGUES } = ARTISN;
const { logUpdateWorkflow } = events.workflow;
const { APPLE_STORE_URL, PLAY_STORE_URL } = APP;
const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const Home: React.FC<Props> = props => {
  const { selectedCatalogue, setSelectedCatalogueId } = useCatalogues();
  const { width: windowWidth } = useWindowSize();
  const isNeededChange = windowWidth <= tablet;
  const bannerCategory = isNeededChange
    ? "banner_home_mobile"
    : "banner_desktop";
  const { data: banners } = useFetchBanners(bannerCategory);
  const { name: catalogueName } = selectedCatalogue;
  const [banner] = banners ?? [];
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;
  const { push } = useRouter();
  const { data: lastCompletedOrder } = useFetchLastCompletedOrder();
  const { id: lastCompletedOrderId } = lastCompletedOrder ?? {};
  const { data: orderRatingStatus } =
    useGetOrderRatingStatus(lastCompletedOrderId);
  const [showRate, setShowRate] = useState(false);
  const { setOpenProductModal } = useProducts();
  const { noCoverage, selectedStore } = useStores();
  const { isOpen } = selectedStore ?? {};
  const isResponsive = windowWidth <= tablet;
  const showStoreNotification =
    !isOpen &&
    !noCoverage &&
    (isResponsive || isMobile) &&
    typeof isOpen !== "undefined";
  const auth = useAuth();
  const { termsAndPrivacyModal } = auth;

  const changeHandler = (e: React.ChangeEvent<any>) => {
    const catalogueId = e.target.value;
    const catalogue = CATALOGUES.find(item => item.catalogueId === catalogueId);

    if (!catalogue) {
      Bugsnag.notify(
        `"${catalogueId}" does not correspond to a valid catalogue ID`
      );
      throw new Error(
        `"${catalogueId}" does not correspond to a valid catalogue ID`
      );
    }

    logUpdateWorkflow({
      previousWorkflow: catalogueName as Workflow,
      nextWorkflow: catalogue.name as Workflow
    });
    setSelectedCatalogueId(catalogueId);
  };

  const clickHandler = (banner: Banner) => {
    const { product_id } = banner;
    if (!WITH_PRODUCT_MODAL) {
      goToBannerUrl(banner);
      return;
    }
    if (product_id) {
      setOpenProductModal(`${product_id}`);
    }
  };

  useEffect(() => {
    if (!orderRatingStatus && typeof orderRatingStatus !== "undefined") {
      setShowRate(true);
    }
  }, [orderRatingStatus]);

  return (
    <Styles className="Home" showStoreNotification={showStoreNotification}>
      <header className="Home__header">
        <Navbar showBrand />
      </header>
      {banner ? (
        <div className="Home__hero">
          <Image
            className="Home__hero__banner"
            image={banner.image_url}
            alt="banner principal"
            onClick={() => clickHandler(banner)}
          />
          <Button
            className="Home__hero__button"
            onClick={() => clickHandler(banner)}
          >
            Pide ahora
          </Button>
        </div>
      ) : null}
      <BannersSlider
        bannerCategoryName="promocionales"
        className="Home__banners"
        title={
          <h2 className="Home__slider__title">
            <span>#</span>Promociones
          </h2>
        }
      />
      <main className="Home__main">
        <div className="Home__cards">
          <div className="Home__card">
            <div className="Home__card__content">
              <label className="Home__card__label">#Cupones</label>
              <h3 className="Home__card__title">Descuentos y promociones</h3>
              <p className="Home__card__description">
                Obtén increíbles beneficios
              </p>
              <RedeemCoupon className="Home__card__coupon-input" inputOnly />
            </div>
            <div className="Home__card__image-container">
              <NextImage
                src="/Hornero-prod/home-cupones_wvxirn.png"
                alt="cupones"
                layout="fill"
              />
            </div>
          </div>
          {!isMobile ? (
            <div className="Home__card">
              <div className="Home__card__content">
                <label className="Home__card__label">
                  <span>#</span>AppMobile
                </label>
                <h3 className="Home__card__title">Descarga nuestra app</h3>
                <p className="Home__card__description">
                  Crea tu cuenta y obtén increíbles beneficios
                </p>
                <Button
                  className="Home__card__download-app-button"
                  onClick={() =>
                    push(
                      isIOS || getOS() === "MacOS"
                        ? APPLE_STORE_URL
                        : PLAY_STORE_URL
                    )
                  }
                >
                  Descargar ahora
                </Button>
              </div>
              <div className="Home__card__image-container">
                <NextImage
                  src="/Hornero-prod/download-app-card_osugjt.png"
                  alt="aplicacion movil"
                  layout="fill"
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="Home__place-your-order">
          <div className="Home__place-your-order__texts">
            <p className="Home__place-your-order__title">Realiza tu pedido</p>
            <p className="Home__place-your-order__description">
              ¿Estás con hambre? Pide a domicilio o recógelo en nuestro local
              más cercano.
            </p>
          </div>
          <ButtonGroup
            className="Home__place-your-order__buttons"
            defaultActive={catalogueName}
            onChange={changeHandler}
          >
            <ButtonGroup.Button
              className="Home__place-your-order__button"
              id={PICK_UP.name}
              value={PICK_UP.catalogueId}
            >
              <span
                className="Home__place-your-order__button-content"
                onClick={() => push("/categories")}
              >
                <PickUpSVG
                  viewBox="0 0 16 16"
                  className="Home__place-your-order__button-icon"
                />
                Para llevar
              </span>
            </ButtonGroup.Button>
            <ButtonGroup.Button
              className="Home__place-your-order__button"
              id={DELIVERY.name}
              value={DELIVERY.catalogueId}
            >
              <span
                className="Home__place-your-order__button-content"
                onClick={() => push("/categories")}
              >
                <DeliverySVG
                  viewBox="0 0 16 16"
                  className="Home__place-your-order__button-icon"
                />
                Delivery
              </span>
            </ButtonGroup.Button>
          </ButtonGroup>
        </div>
        <div className="Home__find-us">
          <FindUsMapSVG
            className="Home__find-us__image"
            viewBox="0 0 240 119"
          />
          <div className="Home__find-us__texts">
            <p className="Home__find-us__title">¡Encuéntranos!</p>
            <p className="Home__find-us__description">
              Visítanos o recoge tu pedido en nuestro local más cercano a ti.
            </p>
          </div>
          <Button
            className="Home__find-us__button"
            onClick={() => push("/profile/stores")}
          >
            <span className="Home__find-us__button-content">
              <LocalSVG viewBox="0 0 20 20" />
              Ver locales
            </span>
          </Button>
        </div>
      </main>
      <footer className="Home__footer">
        <Footer />
      </footer>
      {showRate && !termsAndPrivacyModal ? (
        <RateModal
          orderDetails={lastCompletedOrder}
          closeRate={() => setShowRate(false)}
        />
      ) : null}
    </Styles>
  );
};

Home.defaultProps = {};

export default Home;
